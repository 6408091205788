import axios from "axios";
// import qs from "qs";
// 创建一个 axios 实例
const service = axios.create({
  timeout: 10000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
      // 在请求发送之前做一些处理
      // const token = localStorage.getItem('token');
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      // config.headers["Content-Type"] = 'application/x-www-form-urlencoded';
      config.headers["Content-Type"] = 'application/json';
      // config.headers["Content-Type"] = 'multipart/form-data';

      // const data = qs.stringify({
      //     ...config.data
      // });
      const data = config.data

      return {
        ...config,
        data
      };
    },
    error => {
      // 发送失败
      // console.log(error);
      return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error && error.response) {
        switch (error.response.status) {
          case 400:
            error.message = "请求错误";
            break;
          case 401:
            error.message = "未授权，请登录";
            break;
          case 403:
            error.message = "拒绝访问";
            break;
          case 404:
            error.message = `请求地址出错: ${error.response.config.url}`;
            break;
          case 408:
            error.message = "请求超时";
            break;
          case 500:
            error.message = "服务器内部错误";
            break;
          case 501:
            error.message = "服务未实现";
            break;
          case 502:
            error.message = "网关错误";
            break;
          case 503:
            error.message = "服务不可用";
            break;
          case 504:
            error.message = "网关超时";
            break;
          case 505:
            error.message = "HTTP版本不受支持";
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
);

export default service;
