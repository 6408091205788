import axios from './request'
/**
 * 获取banner列表
 * @param params
 * @returns {*}
 */
export function getBannerList (params) {
  return axios({
    method: 'get',
    url: '/web/news/banner/query',
    params
  })
}
/**
 * 获取专题列表
 * @param params
 * @returns {*}
 */
export function getTopicList (params) {
  return axios({
    method: 'get',
    url: '/web/news/topic/list',
    params
  })
}
/**
 * 获取专题详情
 * @param params
 * @returns {*}
 */
export function getTopicDetail (params) {
  return axios({
    method: 'get',
    url: '/web/news/topic/info',
    params
  })
}

/**
 * 获取新闻
 * @param params
 * @returns {*}
 */
export function getNewsList(params) {
  return axios({
    method: 'get',
    url: '/web/news/query',
    params
  })
}

/**
 * 获取新闻详情
 * @param params
 * @returns {*}
 */
export function getNewsDetail(params) {
  return axios({
    method: 'get',
    url: '/web/news/info',
    params
  })
}
